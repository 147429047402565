import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/en"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.en"

const PrivacyPolicyPage = props => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Privacy Policy | Jubarte Design"
      locale="en"
      altLocale="pt"
      pathname="/privacy-policy/"
      description="Check out our Privacy Policy to understand how we protect your information and what data we collect."
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom">
        <div className="w-md-80 w-lg-60 text-center mx-auto form-search-wrapper">
          <h1 className="display-4 font-size-48--md-down jd-h2-titles">
            <span className="font-weight-bold">Privacy </span>Policy
          </h1>
        </div>
      </div>
    </div>
    <article className="jd-terms">
      <div className="container u-space-1-top">
        <div className="w-lg-90 mx-auto">
          <div className="mb-4">
            <span className="text-muted">Effective Date: June 08th, 2020</span>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Introduction</h2>
            <p>
              Welcome to <b>Jubarte Design</b>.
            </p>
            <p>
              Jubarte Design (“<b>us</b>”, “<b>we</b>”, or “<b>our</b>”) operates{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>{" "}
              (from now on referred to as “<b>Service</b>”). We are committed to protecting your privacy and complying
              with applicable data protection laws. This Privacy Policy outlines how we collect, use, disclose, and
              safeguard information about you. We also describe the choices you have about your information.
            </p>
            <p>
              Our Privacy Policy governs your visit to{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>{" "}
              , all its subdomains, and sub-directories. It explains how we collect, safeguard and disclose information
              that results from your use of our Service.
            </p>
            <p>
              We use your data to provide and improve Service. By using Service, you agree to the collection and use of
              information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms
              used in this Privacy Policy have the same meanings as in our{" "}
              <Link to="/en/terms-of-use/" className="jd-terms-link">
                Terms of Use
              </Link>
              .
            </p>
            <p>
              Our Terms of Use (“<b>Terms</b>”, "<b>Terms of Service</b>") govern all use of our Service, and together
              with the Privacy Policy constitutes your agreement with us (“<b>Agreement</b>”).
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Definitions</h2>
            <p>
              <b>SERVICE</b> means the https://jubarte.design website, all its subdomains, and sub-directories operated
              by Jubarte Design.
            </p>
            <p>
              <b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from
              those and other information either in our possession or likely to come into our possession).
            </p>
            <p>
              <b>USAGE AND LOG DATA</b> is data collected automatically either generated by the use of Service or from
              the Service infrastructure itself (for example, the duration of a page visit).
            </p>
            <p>
              <b>CONTENT DATA</b> is data produced or provided voluntarily by you, by us, or third parties during the
              use of Service.
            </p>
            <p>
              <b>COOKIES</b> re small files stored on your device (computer or mobile device).
            </p>
            <p>
              <b>MARKETING DATA</b> is data we may collect during surveys, contests, events or to enrich
              business-related information about organizations.
            </p>
            <p>
              <b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with
              other persons) determines the purposes for which and how any personal data are or are to be, processed.
            </p>
            <p>
              <b>DATA PROCESSORS</b> (<b>OR SERVICE PROVIDERS</b>) means any natural or legal person who processes the
              data on behalf of the Data Controller. We may use the services of various Service Providers to process
              your data more effectively.
            </p>
            <p>
              <b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.
            </p>
            <p>
              <b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the
              subject of Personal Data.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Information Collection and Use</h2>
            <p>
              We collect several different types of information for various purposes to provide and improve our Service
              to you.
            </p>
            <p>For this Privacy Policy, we may have both roles as a Data Controller and Data Processor of your data.</p>
            <p>
              We act as a Data Controller when we determine the purposes and means of the processing of your Personal
              Data. As a Data Processor, we process Personal Data on behalf of the Data Controller.
            </p>
            <p>
              According to the applicable law, you may also become a Data Controller and or Data Processor, and it will
              impose on you additional obligations.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Types of Data Collected</h2>
            <p>
              <b>Personal Data</b>
            </p>
            <p>
              While using our Service, we may ask you to provide us with certain personally identifiable information
              that can be used to contact or identify you (“<b>Personal Data</b>”). Personally identifiable information
              may include, but is not limited to:
            </p>
            <p className="jd-terms-list-item">(a) E-mail address;</p>
            <p className="jd-terms-list-item">(b) First name and last name;</p>
            <p className="jd-terms-list-item">(c) Organization name;</p>
            <p className="jd-terms-list-item">(d) Cookies and Usage Data;</p>
            <p className="jd-terms-list-item">(e) Profile Photo.</p>
            <p>
              We may use your Personal Data to contact you with newsletters, marketing or promotional materials and
              other information that may be of interest to you. You may opt-out of receiving any, or all, of these
              communications from us by following the unsubscribe link in each e-mail, by changing your e-mail preferences
              on your account management page or by sending us an{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Opt-out of E-mail Communications"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              .
            </p>
            <p>
              <b>Usage and Log Data</b>
            </p>
            <p>
              We may also collect information that your browser sends whenever you visit our Service or access it by or
              through a mobile device (“<b>Usage and Log Data</b>”).
            </p>
            <p>
              This Usage and Log Data may include information such as usage metrics (including usage rates, frequency of
              logins, occurrences of technical errors, diagnostic reports, settings preferences, backup information,
              session replay, and mouse movements), your device's Internet Protocol address (e.g. IP address), browser
              type, browser version, user agent, operating system, language, time zone settings, advertising
              identifiers, the pages of our Service that you visit, the time and date of your visit, the time spent on
              those pages, page response times, page interaction, unique device identifiers, the region in which you are
              located when you are logging in and using the Services, and other diagnostic data.
            </p>
            <p>
              When you access Service with a mobile device, this Usage and Log Data may also include information such as
              the type of mobile device you use, your mobile device unique ID, your mobile operating system, the type of
              mobile Internet browser you use, unique device identifiers, and other diagnostic data.
            </p>
            <p>
              <b>Content Data</b>
            </p>
            <p>
              We may collect data that you voluntarily provide through the Service, e-mails, online forms, order and
              registration forms, surveys, promotion participation, phone calls and online chats with our customer
              service and other means.
            </p>
            <p>
              The content data may include design requests that you create and our team update, messages, comments,
              images, searches, customer support questions, issues, and general feedback that you choose to provide.
            </p>
            <p>
              <b>Tracking Cookies Data</b>
            </p>
            <p>
              We use cookies and similar tracking technologies to track our service's activity, and we hold certain
              information.
            </p>
            <p>
              Cookies are files with a small amount of data, which may include a unique anonymous identifier. Cookies
              are sent to your browser from a website and stored on your device. Other tracking technologies may also be
              used such as beacons, tags and scripts to collect and track information and to improve and analyze our
              Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
              if you do not accept cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <p className="jd-terms-list-item">
              (a) <b>Session Cookies</b>: We use Session Cookies to operate our Service;
            </p>
            <p className="jd-terms-list-item">
              (b) <b>Preference Cookies</b>: We use Preference Cookies to remember your preferences and various
              settings;
            </p>
            <p className="jd-terms-list-item">
              (c) <b>Security Cookies</b>: We use Security Cookies for security purposes;
            </p>
            <p className="jd-terms-list-item">
              (d) <b>Advertising Cookies</b>: Advertising Cookies are used to serve you with advertisements that may be
              relevant to you and your interests and to customize your experience.
            </p>
            <p>
              <b>Marketing Data</b>
            </p>
            <p>
              If you choose to participate in our surveys, contests, events (such as webinars and in-person events), or
              those in which we are affiliated, or request information from us about our Services, we may collect
              information about you related to the survey, contest, or event; your contact information, such as your
              name, e-mail address, telephone number, organization name and address; and general information about your
              organization that you choose to provide, such as annual organization revenue, number of employees, and
              industry.
            </p>
            <p>
              We use standard internet tools such as pixel tags, action tags, web beacons, tokens, git tags or similar
              technologies in connection with Service and e-mail messages in specific formats to, among other things,
              understand the actions of Users and e-mail recipients, to determine the success of advertising or
              promotional campaigns and to compile statistics about Service usage and response rates.
            </p>
            <p>
              We may also use service providers to obtain additional business-related information about your
              organization, such as its legal name, size, and publicly available revenue, to assist us in offering
              appropriate services to your organization's needs.
            </p>
            <p>
              In addition, we may collect information on e-mail open and click rates, including whether individuals
              clicked on links, and which web pages are visited after opening the e-mail.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Use of Data</h2>
            <p>Jubarte Design uses the collected data for various purposes:</p>
            <p className="jd-terms-list-item">(a) to provide and maintain our Service;</p>
            <p className="jd-terms-list-item">(b) to notify you about changes to our Service; </p>
            <p className="jd-terms-list-item">
              (c) to allow you to participate in interactive features of our Service when you choose to do so;
            </p>
            <p className="jd-terms-list-item">(d) to provide customer support;</p>
            <p className="jd-terms-list-item">
              (e) to gather analysis or valuable information so that we can improve our Service;
            </p>
            <p className="jd-terms-list-item">(f) to monitor the usage of our Service;</p>
            <p className="jd-terms-list-item">(g) to prevent fraud and abuse;</p>
            <p className="jd-terms-list-item">(h) to detect, prevent and address technical issues;</p>
            <p className="jd-terms-list-item">(i) for internal research and development purposes;</p>
            <p className="jd-terms-list-item">(j) to fulfill any other purpose for which you provide it;</p>
            <p className="jd-terms-list-item">
              (k) to carry out our obligations and enforce our rights arising from any contracts entered into between
              you and us, including for billing and collection;
            </p>
            <p className="jd-terms-list-item">
              (l) to provide you with notices about your account, subscription, or both including expiration and renewal
              notices, e-mail-instructions, and so forth;
            </p>
            <p className="jd-terms-list-item">
              (m) to provide you with news, special offers and general information about other goods, services and
              events which we offer that are similar to those you have already purchased or enquired about unless you
              have opted not to receive such information;
            </p>
            <p className="jd-terms-list-item">
              (n) to comply with the law and our legal obligations, including to respond to a request or order from a
              court, regulator, or government authority;
            </p>
            <p className="jd-terms-list-item">(o) in any other way we may describe when you provide the information;</p>
            <p className="jd-terms-list-item">(p) for any other purpose with your consent.</p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Retention of Data</h2>
            <p>
              We will retain your Personal Data only for as long as is necessary for the purposes set out in this
              Privacy Policy. We will keep and use your Personal Data to the extent required to comply with our legal
              obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
              shorter period, except when this data is used to strengthen the security or improve our Service's
              functionality, or we are legally obligated to retain this data for more extended periods.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Transfer of Data</h2>
            <p>
              Your information, including Personal Data, may be transferred to – and maintained on – computers located
              outside of your state, province, country or other governmental jurisdiction where the data protection laws
              may differ from those of your jurisdiction.
            </p>
            <p>
              If you are located outside Canada or the United States and choose to provide information to us, please
              note that we transfer the data, including Personal Data, to Canada and the United States and process it
              there.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of such information represents your
              agreement to that transfer.
            </p>
            <p>
              Under the General Data Protection Regulation ("<b>GDPR</b>") and other data protection laws, information
              about you may only be transferred from your region to other regions if specific requirements are met. For
              instance, under the GDPR, information about you may be transferred from the European Economic Area ("
              <b>EEA</b>") to outside the EEA if adequate data protections are in place. Jubarte Design's headquarters
              in Canada manage our Service. As the European Commission considers Canada to be a country that provides
              adequate data protection, information about you may be transferred from the EEA to Canada.
            </p>
            <p>
              We also use third-party service providers, such as managed hosting providers, credit card processors, and
              technology partners, to provide the software, networking, infrastructure and other services required to
              operate the Service. These third-party providers may process or store personal data on servers outside of
              the EEA and Switzerland, including in Canada or the United States. We rely on adequacy (if sent to
              Canada), the service provider's registration in the EU-US Privacy Shield and Swiss-US Privacy Shield (if
              sent to the United States), and/or standard contractual clauses (if sent to the United States or onward to
              other countries) to ensure that information about you is lawfully transferred under EU law.
            </p>
            <p>
              Jubarte Design will take all the steps reasonably necessary to ensure that your data is treated securely
              and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in place including the security of your data
              and other personal information.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Disclosure of Data</h2>
            <p>We may disclose personal information that we collect, or you provide:</p>
            <p className="jd-terms-list-item">
              <b>(a) Disclosure for Law Enforcement:</b>
            </p>
            <p className="jd-terms-list-item">
              Under certain circumstances, we may be required to disclose your Personal Data if required to do so by
              law, to comply with legal processes, in response to valid requests by public authorities in any country in
              which we operate, including outside your country of residence.
            </p>
            <p className="jd-terms-list-item">
              <b>(b) Business Transaction:</b>
            </p>
            <p className="jd-terms-list-item">
              If our subsidiaries or we are involved in a merger, acquisition, joint venture, assignment, transfer or
              other disposition of all or any portion of our business, assets or stock (including without limitation in
              connection with any bankruptcy or similar proceedings) or asset sale, any and all your Personal Data may
              be transferred.
            </p>
            <p className="jd-terms-list-item">
              <b>(c) Other cases. We may disclose your information also:</b>
            </p>
            <p className="jd-terms-list-subitem">(i) to our subsidiaries and affiliates;</p>
            <p className="jd-terms-list-subitem">
              (ii) to contractors, service providers, strategic partners, and other third parties we use to support our
              business;
            </p>
            <p className="jd-terms-list-subitem">(iii) to fulfill the purpose for which you provide it;</p>
            <p className="jd-terms-list-subitem">
              (iv) to protect the rights, privacy, safety or property of us, our affiliated entities, you, or others;
            </p>
            <p className="jd-terms-list-subitem">(v) to protect our operations or those of any affiliated entities;</p>
            <p className="jd-terms-list-subitem">(vi) to include your organization's logo on our website;</p>
            <p className="jd-terms-list-subitem">
              (vii) for any other purpose disclosed by us when you provide the information;
            </p>
            <p className="jd-terms-list-subitem">(vii) with your consent in any other cases.</p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Security of Data</h2>
            <p>
              The security of your data is fundamental to us, but remember that no transmission method over the Internet
              or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
              protect your Personal Data, we cannot guarantee its absolute security.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
            <p>
              If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data
              protection rights covered by GDPR. See more at{" "}
              <a
                href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </a>
              .
            </p>
            <p>
              We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal
              Data.
            </p>
            <p>
              If you wish to be informed of what Personal Data we hold about you and if you want it to be removed from
              our systems, please send us an{" "}
              <a href="mailto:contact@jubarte.design?subject=Personal Data Inquiry" className="jd-terms-mailto">
                e-mail
              </a>
              .
            </p>
            <p>In certain circumstances, you have the following data protection rights:</p>
            <p className="jd-terms-list-item">
              (a) the right to access, update or to delete the information we have on you;
            </p>
            <p className="jd-terms-list-item">
              (b) the right of rectification. You have the right to have your information rectified if that information
              is inaccurate or incomplete;
            </p>
            <p className="jd-terms-list-item">
              (c) the right to object. You have the right to object to our processing of your Personal Data;
            </p>
            <p className="jd-terms-list-item">
              (d) the right of restriction. You have the right to request that we restrict the processing of your
              personal information;
            </p>
            <p className="jd-terms-list-item">
              (e) the right to data portability. You have the right to be provided with a copy of your Personal Data in
              a structured, machine-readable and commonly used format;
            </p>
            <p className="jd-terms-list-item">
              (f) the right to withdraw consent. You also have the right to withdraw your consent at any time where we
              rely on your consent to process your personal information.
            </p>
            <p>
              Please note that we may ask you to verify your identity before responding to such requests. Please note,
              we may not be able to provide Service without some necessary data.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority about our collection and use of your
              Personal Data. For more information, please contact your local data protection authority in the European
              Economic Area (EEA).
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">
              Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
            </h2>
            <p>
              CalOPPA is the first state law in the United States to require commercial websites and online services to
              post a privacy policy. The law's reach stretches well beyond California to require a person or company in
              the United States (and conceivable the world) that operates websites collecting personally identifiable
              information from California consumers to post a conspicuous privacy policy on its website stating
              precisely the data being collected and those individuals with whom it is being shared and to comply with
              this policy. See more at{" "}
              <a
                href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </a>
            </p>
            <p>According to CalOPPA, we agree to the following:</p>
            <p className="jd-terms-list-item">(a) users can visit our site anonymously;</p>
            <p className="jd-terms-list-item">
              (b) our Privacy Policy link includes the word "Privacy", and can easily be found on the page specified
              above on the home page of our website;
            </p>
            <p className="jd-terms-list-item">
              (c) we will notify our users of any privacy policy changes on our Privacy Policy Page;
            </p>
            <p className="jd-terms-list-item">
              (d) users can change their personal information by sending us an{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Request to update Personal Information"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              .
            </p>
            <p>
              <b>Our Policy on "Do Not Track" Signals:</b>
            </p>
            <p>
              We do not respond to Do Not Track (DNT) signals at this time. Some third-party tools may track your
              actions when you are browsing and accessing content.
            </p>
            <p>
              Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be
              tracked. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
              browser.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Service Providers</h2>
            <p>
              We may employ third party companies and individuals to facilitate our Service ("<b>Service Providers</b>
              "), provide Service on our behalf, perform Service-related services or assist us in analyzing how our
              Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to perform these tasks on our behalf. They are
              obligated not to disclose or use it for any other purpose.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Analytics</h2>
            <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
            <p>
              <b>Google Analytics</b>
            </p>
            <p>
              Google Analytics is a web analytics service offered by Google LLC that tracks and reports website traffic.
              Google uses the data collected to track and monitor the use of our Service. This data is shared with other
              Google services. Google may use the collected data to contextualize and personalize the ads of its
              advertising network.
            </p>
            <p>
              Google Analytics on this Service might use Google's Interest-based advertising, 3rd-party audience data
              and information from the DoubleClick Cookie to extend analytics with demographics, interests and ads
              interaction data.
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              We also encourage you to review Google's policy for safeguarding your data:{" "}
              <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/analytics/answer/6004245
              </a>
              .
            </p>
            <p>
              <b>Google Ads conversion tracking</b>
            </p>
            <p>
              Google Ads conversion tracking is an analytics service provided by Google LLC. It connects data from the
              Google Ads advertising network with actions performed on the Service.
            </p>
            <p>
              To understand how Google Ads conversion tracking works, please visit Google's guide at{" "}
              <a
                href="https://support.google.com/google-ads/answer/1722022?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/google-ads/answer/1722022?hl=en
              </a>
              .
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              <b>Amplitude</b>
            </p>
            <p>Amplitude is an analytics service provided by Amplitude, Inc that tracks and reports website traffic.</p>
            <p>
              For more information on Amplitude's privacy practices, please visit the Amplitude Privacy & Terms web
              page:{" "}
              <a
                href="https://amplitude.com/privacy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://amplitude.com/privacy
              </a>
              .
            </p>
            <p>
              <b>Facebook Ads conversion tracking (Facebook pixel)</b>
            </p>
            <p>
              Facebook Ads conversion tracking (Facebook pixel) is an analytics service provided by Facebook, Inc. that
              connects data from the Facebook advertising network with actions performed on this Service. The Facebook
              pixel tracks conversions that can be attributed to ads on Facebook, Instagram and Audience Network.
            </p>
            <p>
              For more information on the privacy practices of Facebook, please review its Data Policy at{" "}
              <a
                href="https://www.facebook.com/about/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/about/privacy/
              </a>
              .
            </p>
            <p>
              <b>LinkedIn conversion tracking</b>
            </p>
            <p>
              LinkedIn conversion tracking is an analytics service provided by LinkedIn Corporation that connects data
              from the LinkedIn advertising network with actions performed on the Service.
            </p>
            <p>
              To understand how LinkedIn's conversion tracking and website analytics work, please review its Terms for
              Conversion Tracking, Analytics and LinkedIn Audience Matching at{" "}
              <a
                href="https://www.linkedin.com/legal/conversion-tracking"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/legal/conversion-tracking
              </a>
              .
            </p>
            <p>
              For more information on the privacy practices of LinkedIn Corporation, we encourage you to review its
              Privacy Policy at{" "}
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Continuous Integration, Continuous Delivery and Software Development tools</h2>
            <p>We may use third-party Service Providers to automate the development process of our Service.</p>
            <p>
              <b>Netlify</b>
            </p>
            <p>
              We use Netlify to host some of our websites used to deliver the Service to you. Netlify is a service
              provided by Netlify, Inc..
            </p>
            <p>
              You can find more information about Netlify's privacy policies here:{" "}
              <a
                href="https://www.netlify.com/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.netlify.com/privacy/
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Behavioral Remarketing</h2>
            <p>
              Jubarte Design uses remarketing services to advertise on third party websites to you after you visit our
              Service. Our third-party vendors and we use cookies to inform, optimize and serve ads based on your past
              visits to our Service.
            </p>
            <p>
              In addition to any opt-out offered by any of the services below, the User may opt-out of a third-party
              service's use of cookies by visiting the Network Advertising Initiative opt-out page at{" "}
              <a
                href="https://optout.networkadvertising.org/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://optout.networkadvertising.org/
              </a>
              .
            </p>
            <p>
              <b>Google Ads (AdWords)</b>
            </p>
            <p>Google LLC provides Google Ads (AdWords) remarketing service.</p>
            <p>
              You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads
              by visiting the Google Ads Settings page:{" "}
              <a
                href="https://www.google.com/settings/ads"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.google.com/settings/ads
              </a>
              .
            </p>
            <p>
              Google also recommends installing the Google Analytics Opt-out Browser Add-on –{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>{" "}
              – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to
              prevent their data from being collected and used by Google Analytics.
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              <b>Facebook Custom Audience and Facebook Remarketing</b>
            </p>
            <p>
              We use Facebook Custom Audience and Facebook Remarketing, which are remarketing and behavioural targeting
              services provided by Facebook Inc that connects the activity of this Service with the Facebook advertising
              network.
            </p>
            <p>
              You can learn more about interest-based advertising from Facebook by visiting this page:{" "}
              <a
                href="https://www.facebook.com/help/164968693837950"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/help/164968693837950
              </a>
              .
            </p>
            <p>
              To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:{" "}
              <a
                href="https://www.facebook.com/help/568137493302217"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/help/568137493302217
              </a>
              .
            </p>
            <p>
              Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the
              Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through
              the Digital Advertising Alliance in the USA{" "}
              <a
                href="https://www.aboutads.info/choices/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.aboutads.info/choices/
              </a>
              , the Digital Advertising Alliance of Canada in Canada{" "}
              <a href="https://youradchoices.ca/" target="_blank" rel="nofollow noopener noreferrer" className="jd-terms-link">
                https://youradchoices.ca/
              </a>{" "}
              or the European Interactive Digital Advertising Alliance in Europe{" "}
              <a
                href="https://www.youronlinechoices.eu/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.youronlinechoices.eu/
              </a>
              , or opt-out using your mobile device settings.
            </p>
            <p>
              For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:{" "}
              <a
                href="https://www.facebook.com/privacy/explanation"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/privacy/explanation
              </a>
              .
            </p>
            <p>
              <b>LinkedIn Website Retargeting</b>
            </p>
            <p>
              LinkedIn Website Retargeting is a remarketing and behavioural targeting service provided by LinkedIn
              Corporation that connects the activity of this Service with the LinkedIn advertising network.
            </p>
            <p>
              You can opt-out from LinkedIn's Website Retargeting by adjusting your settings regarding personalized ads
              at the following website:{" "}
              <a
                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
              </a>
              .
            </p>
            <p>
              You can learn more about the privacy practices and policies of LinkedIn by visiting their Privacy Policy
              page:{" "}
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Tag Management</h2>
            <p>
              This type of service helps Jubarte Design to manage the tags or scripts needed on this Service in a
              centralized fashion, which results in the Users' Data flowing through these services, potentially
              resulting in its retention.
            </p>
            <p>
              <b>Google Tag Manager</b>
            </p>
            <p>Google Tag Manager is a tag management service provided by Google LLC.</p>
            <p>
              We encourage you to read Google Tag Manager Terms of Service at{" "}
              <a
                href="https://support.google.com/tagmanager/answer/7157428"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/tagmanager/answer/7157428
              </a>
              .
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              <b>Segment</b>
            </p>
            <p>Segment is a tag management service provided by Segment.io Inc..</p>
            <p>
              You can read the Privacy Policy for Segment here:{" "}
              <a
                href="https://segment.com/legal/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://segment.com/legal/privacy/
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Payments</h2>
            <p>
              We may provide paid products, services or both within Service. In that case, we use third-party services
              for payment processing (e.g. payment processors).
            </p>
            <p>
              We will not store or collect your payment card details. That information is provided directly to our
              third-party payment processors whose use of your personal information is governed by their Privacy Policy.
              These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
              requirements help ensure the secure handling of payment information.
            </p>
            <p>The payment processor we work with is:</p>
            <p>
              <b>Stripe:</b>
            </p>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a
                href="https://stripe.com/us/privacy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://stripe.com/us/privacy
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Chat</h2>
            <p>
              We use the chat tool Gist provided by ConvertFox Global, Inc to communicate with you and to improve the
              user experience.
            </p>
            <p>
              We use Gist to send messages and e-mails to you and to help you with issues. To do this, we send the
              contact information you provided during registration to Gist, e.g. your username and your e-mail address.
              We further send information about the website that sent you to our website, products and services
              (referrer URL), your browser and your location. This information will be stored on servers of Gist.
            </p>
            <p>
              You can find more privacy information concerning Gist here:{" "}
              <a
                href="https://getgist.com/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://getgist.com/privacy/
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Links to Other Sites</h2>
            <p>
              Our Service may contain links to other sites that are not operated by us. If you click a third party link,
              you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of
              every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies or practices of
              third-party sites or services.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Children's Privacy</h2>
            <p>
              Our Services are not intended for use by children under the age of 18 ("<b>Child</b>" or "<b>Children</b>
              ").
            </p>
            <p>
              We do not knowingly collect personally identifiable information from Children under 18. If you become
              aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have
              collected Personal Data from Children without verifying parental consent, we take steps to remove that
              information from our servers.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
              Privacy Policy on this page.
            </p>
            <p>
              If we have your e-mail address, we will let you know via e-mail and a prominent notice on our Service,
              before the change becomes effective and update "effective date" at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
              are effective when they are posted on this page.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <p>
              By:{" "}
              <a href="mailto:contact@jubarte.design?subject=Privacy Policy Questions" className="jd-terms-mailto">
                e-mail
              </a>
              .
            </p>
            <p>
              Via chat on our website at{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default PrivacyPolicyPage
